import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  lang: string='fr';
  @Output() setSelectLang = new EventEmitter<string>();

  public value:any;
  private listLang = ['en', 'fr'];

  public url: any;

  public contactMethods = [
    { id: 'en', label: "English" },
    { id: 'fr', label: "Français" },
    { id: 'es', label: "Español" },
    { id: 'pt', label: "Portuguese" },
    { id: 'ar', label: "عربى" }
  ];


  constructor( private translate: TranslateService) { }

  changeLang() {

    console.log("HEADER COMPONENT lang Change");
    console.log(this.lang);

    this.url = 'assets/lang/' + this.lang + '.png';
    localStorage.setItem('lang_UPDATE', this.lang);
    this.translate.use(this.lang);
    //send to app
    this.setSelectLang.emit(this.lang)
  }

  get nextLang() {
    const index = this.listLang.indexOf(this.lang);
    return this.listLang[(index + 1) % this.listLang.length];
  }

  ngOnInit() {
     //   this.value = navigator.language
     /*
    switch (navigator.language) {
      case 'fr-FR': this.value = 'fr'
        break;
      case 'en-EN': this.value = 'en'
        break;
      default: this.value = 'fr'
        break;
    }
    */

    if(localStorage.getItem('lang_UPDATE')){
      this.lang = localStorage.getItem('lang_UPDATE')
    } else {
      const language = navigator.language.substr(0, 2).toLowerCase();
      this.lang = (language !== 'en' && language !== 'es' && language !== 'fr' && language !== 'pt' && language !== 'ar') ? 'en' : language;
      localStorage.setItem('lang_UPDATE', this.lang);
    }
    console.log(this.lang);
    this.translate.use(this.lang);
    this.url = 'assets/lang/' + this.lang + '.png';
  }

}
