import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpService} from './http.service';
import {TranslateService} from '@ngx-translate/core';
import {formatDate} from '@angular/common';

import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  lang: any ;
  private space = '@G@A@T@E@';
  // private space = 'DEMO';
  //private space = 'GOUROU';
  @ViewChild('emailInput') emailInput: ElementRef;
  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl  {
    return this.loginForm.get('password');
  }

  get email_recup(): AbstractControl  {
    return this.loginForm.get('email_recup');
  }

  mailReInit: any = "";
  msgSendReInit: boolean = false;

  loginForm: FormGroup;
  loginFormInit_: FormGroup;
  loginFormResetPwd: FormGroup;


  //link:string= "update-delta.local";
  //link:string= "update.delta-monitoring.com";
  link:string= window.location.href;
  t_bksb:string= "PROD";


  public confirmForm: FormGroup =  new FormGroup({});

  get password_(): AbstractControl {
    return this.confirmForm.get('password_') || new FormControl();
  }

  get confirmPassword(): AbstractControl {
    return this.confirmForm.get('confirmPassword') || new FormControl();
  }

  errorLoginReI = null;

  errorLogin = null;

  errorRPWD = null;
  public statPassword_reset = null;
  loading: boolean;
  private token: number;

  public key:any;

  public reInitPassword: boolean = false;
  public resetPwd: boolean = false;

  public usermail: any;

  initFail :boolean= true;
  errorLoginGlobal :any= {id:1};
  constructor(private httpService: HttpService, private translate: TranslateService, private _router: Router) {
    this.token = (new Date()).getTime();
    this.loading  = false;
/*
    switch (navigator.language) {
      case 'fr-FR': this.lang = 'fr'
        break;
      case 'en-EN': this.lang = 'en'
        break;
      default: this.lang = 'fr'
        break;
    }
    this.getSelectLang(this.lang);*/
  }

  getSelectLang($event) {
    this.lang = $event;
    this.translate.use(this.lang);
    localStorage.setItem('lang_UPDATE', this.lang);
  }



  forgotPassword() {
    /*localStorage.setItem('RESET_KEY', btoa(this.space + ':PROD'));
    location.href = './password_reset';*/
    this.mailReInit = "";
    this.reInitPassword = true;
  }

  reinitialisation(){
    this.loading = true;
    // Envoi de requete de reinitialisation de mot de passe Delta
    // console.log("Send renit pass reinitialisation ")
    // console.log(this.lang);

    this.httpService.post('reset_password', {
      action: "reset",
      e_bksb: this.space,
      email: this.mailReInit,
      lang: this.lang,
      link: this.link,
      method: 1,
      t_bksb: this.t_bksb,
    }).subscribe((data: any) => {
        this.loading = false;
        this.msgSendReInit = true;
        }, (error) => {
          this.errorLogin = true;
          this.loading = false;
    });

      // this.msgSendReInit = true;
  }


  verify(){
    console.log(this.mailReInit)
    /**/
   // this.errorLoginReI = false
    if(this.mailReInit.includes('@')){
      this.errorLoginReI = false
    } else {
      this.errorLoginReI = true
    }
  }

  closeReInit(){
    this.reInitPassword = false;
    this.msgSendReInit = false;
  }


  checkSpace(){
    this.loading = true;

    this.httpService.get('Connexion', {get: 'space_status',  space: this.space}).subscribe((data: any) => {
      this.loading = false;
      const currentValue = parseInt((data.id || '').toString(), 10);
      this.translate.get(['HOME.ERROR_EXPIRED', 'HOME.ERROR_LOCK'], {date: formatDate(data.date, 'mediumDate', this.translate.currentLang)  }).subscribe((transData: any) => {
        switch (currentValue) {
          case 1: // Will Expire
            this.errorLoginGlobal = {
              id: 1,
              msg: transData['HOME.ERROR_EXPIRED']
            };
            break;

          case -1: // Expired
            this.errorLoginGlobal = {
              id: -1,
              msg: transData['HOME.ERROR_LOCK']
            };
            break;

          default: // Validate
            this.initFail = false;

        }
        this.emailInput.nativeElement.focus();
      });
    });
  }


  login() {
    if (this.loading) {
      return;
    }

    this.errorLogin = null;
    if (this.email.invalid || this.password.invalid) {
      return;
    }

    this.loading = true;
    this.lang = localStorage.getItem('lang_UPDATE');

    this.httpService.post('Connexion', {
      action: 'l',
      e: this.space,
      l: this.lang,
      m: this.email.value,
      method: 1,
      now: this.token,
      p: this.password.value,
      t: 1,
      t_bksb: `${this.token}`,
    }).subscribe((data: any) => {
      if (!data.l) {
        this.errorLogin = data;
        this.loading = false;
        return;
      }
      if (window.localStorage) {
        localStorage.setItem('token', data.k);
      }
      document.location.href = this.httpService.urlClient + '?' + data.k;
    }, (error) => {
      this.errorLogin = true;
      this.loading = false;
    });


  }

  connect(){

    this.statPassword_reset = null;
    this.errorRPWD = null;
    this.errorLoginReI = null;
    this.reInitPassword = false;
    this.resetPwd = false;
    /**/
    this._router.navigate(['']);
  }

  resetpwd(){
    this.errorRPWD = false;

    if (this.password_.value != this.confirmPassword.value){
      this.errorRPWD = true;
      return;
    }
 //   this.statPassword_reset = true;

    this.httpService.post(
      'reset_password',
      {
        method: 1,
        psw: this.password_.value,
        action: 'change_psw',
        k:  this.key
      }).subscribe((info: any) => {
         this.statPassword_reset = true;
      });

  }


  ngOnInit(): void {

    const language = localStorage.getItem('lang_UPDATE') || navigator.language.substr(0, 2).toLowerCase();
    this.lang = (language !== 'en' && language !== 'es' && language !== 'fr' && language !== 'pt' && language !== 'ar') ? 'en' : language;
    this.translate.setDefaultLang(this.lang);

    this.checkSpace();

    this.loginForm =  new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      password: new FormControl(null, [Validators.required])
    });
    this.loginForm.get('email').setValidators([Validators.email, Validators.required]);

    this.loginFormInit_ =  new FormGroup({
      email_recup: new FormControl(null, [Validators.email, Validators.required])
    });

    this.loginFormInit_.get('email_recup').setValidators([Validators.email, Validators.required]);

    this.confirmForm =  new FormGroup({
      confirmPassword: new FormControl(null, [Validators.minLength(8), Validators.required]),
      password_: new FormControl(null, [Validators.required, Validators.minLength(8)])
    });

    let url = window.location.href;

    var urlinfos = url.split('/');

    if(urlinfos[4] == "reset_password"){
      // En mode recovery de mot de passe
      this.resetPwd = true;
      this.key = urlinfos[5];
      this.httpService.get(
        'reset_password',
        {
          method: 2,
          get: 'info',
          k: this.key
        }).subscribe((info: any) => {
        this.usermail = info.email;
        });
    } else {
      console.log('no id')
    }
  }


}
