

<app-header (setSelectLang)="getSelectLang($event)" *ngIf="!reInitPassword"></app-header>

<div class="login-content">

  <div class="content">

    <div _ngcontent-pmd-c46="" class="top-panel space" style="margin-left: 90%;position: fixed;margin-top: -45%;" *ngIf="reInitPassword">
      <a _ngcontent-pmd-c46="" class="close-button" (click)="closeReInit()" style="cursor: pointer;">
        <svg _ngcontent-pmd-c46="" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path  _ngcontent-pmd-c46="" d="M32.9897 21.9888L22.0081 32.9704" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path  _ngcontent-pmd-c46="" d="M32.9932 32.977L22.0023 21.9839" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path  _ngcontent-pmd-c46="" fill-rule="evenodd" clip-rule="evenodd" d="M37.4332 6.30273H17.5668C10.6436 6.30273 6.30322 11.2046 6.30322 18.1415V36.8598C6.30322 43.7967 10.623 48.6986 17.5668 48.6986H37.4309C44.377 48.6986 48.6991 43.7967 48.6991 36.8598V18.1415C48.6991 11.2046 44.377 6.30273 37.4332 6.30273Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </a>
    </div>

    <div class="content-items">

      <div class="logo">
        <img src="assets/images/logo_2.png">
      </div>
      <div class="welcome" *ngIf="(!reInitPassword) && (!resetPwd)">
        {{'HOME.TEXT_' | translate}}
      </div>

      <div class="welcome" *ngIf="(resetPwd) && (!statPassword_reset)">
        <p style="font-size: 18px;line-height: initial;">{{'HOME.TEXT_RESETPW' | translate}} <b style="color: black;"> {{usermail}} </b> </p>
      </div>

      <div class="welcome" *ngIf="statPassword_reset">
        <p style="font-size: 18px;line-height: initial;">{{'HOME.MSG_RESET' | translate}} <b style="color: #4bc3ff;cursor: pointer;" (click)="connect()"> {{'HOME.CONN_' | translate}}  </b> </p>
      </div>

      <div class="welcome" *ngIf="reInitPassword">
        <h3 style="font-size: 39px; line-height: normal;"> {{'HOME.TEXT_REINIT' | translate}} </h3>
         <p style="font-size: 18px;line-height: initial;" *ngIf="!msgSendReInit" >{{'HOME.DESCR_REINIT' | translate}}</p>
         <p style="font-size: 18px;line-height: initial;" *ngIf="msgSendReInit" >{{'HOME.MSG_REINIT' | translate}}</p>
      </div>

      <form (ngSubmit)="login()" [formGroup]="loginForm" *ngIf="(!reInitPassword) && (!resetPwd)">

        <div class="input">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
                <div>
                 <img src="assets/images/mail.png">
                </div>
                <div>
                  {{'HOME.EMAIL' | translate}}
                </div>
              </div>
              <input class="form-control" formControlName="email" email="true" placeholder="{{'HOME.EMAIL_' | translate}}" />
            </div>
            <div *ngIf="email.errors?.email" class="error">
              {{'HOME.ERROR_EMAIL' | translate}}
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
                <div>
                  <img src="assets/images/unock.png">
                </div>
                <div>
                  {{'HOME.PASSWORD' | translate}}
                </div>
              </div>
              <input class="form-control" formControlName="password" type="password" placeholder="{{'HOME.PASSWORD_' | translate}}" />
            </div>

          </div>

          <div class="form-group">
            <div *ngIf="errorLogin" class="text-danger" style="font-weight: bold; text-align: center">
              {{errorLogin.msg}}
            </div>
          </div>
        </div>


        <div class="forgot">
          <span>
            {{'HOME.FORGOT' | translate}} <a style="cursor: pointer" class="click" (click)="forgotPassword()" >{{'HOME.CLICK_HERE' | translate}}</a>
          </span>
        </div>

        <div class="validate">
          <p>
          <button type="submit" [disabled]="email.errors?.email || email.errors?.required || password.errors?.required " *ngIf="!loading && (!initFail || errorLoginGlobal.id==1)">{{'HOME.LOGIN' | translate}}</button>
          </p>

          <!-- Message ESPACE DESACTIVER -->
          <div *ngIf="initFail" class="text-center" style="font-weight: bold;margin-top:20px;" [ngClass]="errorLoginGlobal.id==1||errorLoginGlobal.id=='1'? 'text-warning':'text-danger'" >
            <span style="padding: 10px;">{{errorLoginGlobal.msg}}</span>
          </div>

          <div class="loader" style="left: 43%;"  *ngIf="loading">Loading...</div>
        </div>


      </form>

      <!-- Formular of reset password -->
      <form (ngSubmit)="resetpwd()" *ngIf="(resetPwd) && (!statPassword_reset)" [formGroup]="confirmForm">
        <div class="input">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
                <div>
                  <img src="assets/images/unock.png">
                </div>
                <div>
                  {{'HOME.PASSWORD' | translate}}
                </div>
              </div>
              <input class="form-control" formControlName="password_"  type="password" placeholder="{{'HOME.PASSWORD_' | translate}}" />
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
                <div>
                  <img src="assets/images/unock.png">
                </div>
                <div>
                  {{'HOME.CONFIRM_PWD' | translate}}
                </div>
              </div>
              <input class="form-control" formControlName="confirmPassword" type="password" placeholder="{{'HOME.CONFIRM_PWD_' | translate}}" />
            </div>
            <div *ngIf="errorRPWD" class="text-error error-message">
              {{'HOME.NOT_SAME' | translate}}
            </div>
          </div>
        </div>

        <div class="validate">
          <button type="submit"> {{'HOME.LOGIN_' | translate}} </button>
          <div class="loader"  *ngIf="loading">Loading...</div>
        </div>
      </form>
       <!-- End Formular of reset password -->

      <form (ngSubmit)="reinitialisation()"  *ngIf="(reInitPassword) && (!msgSendReInit) && (!resetPwd)" >
        <div class="input">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
                <div>
                <img src="assets/images/mail.png">
                </div>
                <div>
                  {{'HOME.EMAIL' | translate}}
                </div>
              </div>
              <input class="form-control" [(ngModel)]="mailReInit" (input)="verify()"
              name="mailReInit" email="true" placeholder="{{'HOME.EMAIL_RECUP' | translate}}" />
            </div>
          </div>
          <p *ngIf="errorLoginReI" style="font-weight: bold; text-align: center;color: red;"> {{'HOME.ERROR_EMAIL' | translate}} </p>
        </div>

        <div class="validate">
          <button type="submit" *ngIf="(!loading) && (!msgSendReInit)" [disabled]="mailReInit == ''">{{'HOME.LOGIN_' | translate}}</button>
          <div class="loader"  *ngIf="loading">Loading...</div>
        </div>
      </form>


    </div>

  </div>

</div>
