import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private metaParams = {};
  constructor(private http: HttpClient) {

  }
  public static get production() {
    return true;
  }
  private static get ROOT_URL() {
      return this.production ? 'app/' : 'http://localhost:7070/delta_monitoring/';
    // return'app/';
  }
  public static get SERVER_URL() {
    return HttpService.ROOT_URL + 'data/';
    // return 'http://localhost:7070/delta_web_service/data/';
  }

  private processGetParams(params, reqOpts) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (!params.hasOwnProperty(k)) {
          continue;
        }
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    for (const k in this.metaParams) {
      if (!this.metaParams.hasOwnProperty(k)) {
        continue;
      }
      reqOpts.params = reqOpts.params.set(k, this.metaParams[k]);
    }
    return reqOpts;
  }

  private processPostParams(reqOpts: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    for (const k in this.metaParams) {
      if (!this.metaParams.hasOwnProperty(k)) {
        continue;
      }
      reqOpts.params = reqOpts.params.set(k, this.metaParams[k]);
    }
    reqOpts.reportProgress = true;
    return reqOpts;
  }

  get urlClient() {
    return HttpService.ROOT_URL;
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    console.log(HttpService.SERVER_URL + `${endpoint}.php`);
    return this.http.get<any>(HttpService.SERVER_URL + `${endpoint}.php` , this.processGetParams(params, reqOpts));
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(HttpService.SERVER_URL + `${endpoint}.php` , body, this.processPostParams(reqOpts));
  }


  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(HttpService.SERVER_URL + `${endpoint}.php` , body, this.processPostParams(reqOpts));
  }

  delete(endpoint: string, params?: any, reqOpts?: any) {
    return this.http.delete(HttpService.SERVER_URL + `${endpoint}.php` ,  this.processGetParams(params, reqOpts));
  }

  uploadFile(endpoint: string, body: {file: File, key: string, name: string}, others?: {key: string, value: any}[]) {
    // const reqOpts = this.processPostParams({});
    const formData: FormData = new FormData();
    formData.append(body.key, body.file, body.name);
    if (others) {
      for (const other of others ) {
        formData.append(other.key, other.value);
      }
    }
    return this.post(endpoint , formData);
  }


}
