<!--
-->
<div class="banner">
   <div class="row " style="display: flex;z-index: 10000;">
      <!--
        <div class="col-2">
         <img src="./assets/img/logo_delta.png" style="width: 95px;">
        </div>
        <div class="col-8" style="text-align: center;">
          <h3 style="margin-top: 2%; font-family: monospace; color: #006699;" > {{'HEADER.TITLE' | translate}}</h3>
        </div>
      -->
      <!-- Langue Image -->
      <!-- <div class="col-1" style="padding: 0%;flex: 1;">
        <img src={{url}} style="width: 45px;margin-top: 2px;">
      </div> -->

      <div class="col-1 langueSelect" style="padding: 0%;">

        <!--

        <select class="form-control" style="margin-top: 10%;" (change)="change()" [(value)]="value">
            <option style="background-image:url(./assets/img/us.jpg);" value='en'> English </option>
            <option style="background-image:url(./assets/img/fr.jpg);"  value='fr'> Français</option>
            <option value='es'>Español</option>
            <option value='pt'>Portuguese</option>
            <option  value='ar'>عربى</option>
          </select>
          -->

        <!-- Langue change -->

        <select name="contactMethod" id="contactMethod" class="form-control optLang" (change)="changeLang()" [(ngModel)]="lang"
          onfocus='this.size=4;' onblur='this.size=0;' onchange='this.size=1; this.blur();'>
          <option  class="optionSelect" *ngFor="let method of contactMethods" [value]="method.id" [ngStyle]="{'display':lang ==method.id ? 'none' : 'block' }">
              {{ method.label }}
          </option>
        </select>

      </div>


  <!--
   <img src="./assets/img/us.jpg"  class="logo" (click)="changeLang('en')" *ngIf="lang == 'fr'" />
   <span *ngIf="lang == 'fr'" (click)="changeLang('en')"> English </span>

   <img src="./assets/img/fr.jpg"  class="logo" (click)="changeLang('fr')" *ngIf="lang == 'en'" />
   <span *ngIf="lang == 'en'" (click)="changeLang('fr')"> Français </span>
  -->


</div>

<div style="flex: 1"></div>

</div>
